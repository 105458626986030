<template>
  <div class="card card-custom px-md-10">
    <div class="card-body p-0">
      <div class="row px-8 px-lg-10 py-8">
        <h2>Mülk Ekle</h2>
      </div>
      <!--begin: Wizard-->
      <div
        class="wizard wizard-1"
        id="kt_wizard_v1"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps pt-8 pt-lg-10 px-8 px-lg-10 mx-4">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-label">
                <div class="wizard-box">
                  <p>1</p>
                </div>
              </div>
              <div class="wizard-divider d-lg-block d-none"></div>
              <p class="wizard-text d-block d-lg-none">Mülk Çeşidi</p>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <div class="wizard-box">
                  <p>2</p>
                </div>
              </div>
              <div class="wizard-divider d-lg-block d-none"></div>
              <p class="wizard-text d-block d-lg-none">Ödeme Yöntemi</p>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <div class="wizard-box">
                  <p>3</p>
                </div>
              </div>
              <p class="wizard-text d-block d-lg-none">Müşteri İşlemleri</p>
            </div>
          </div>
          <div class="d-lg-block d-none">
            <div
              class="d-flex px-8 px-lg-10 pb-8 pb-lg-10 pt-2 justify-content-between"
            >
              <p class="wizard-text">Mülk Çeşidi</p>
              <p class="wizard-text">Mülk Bilgileri</p>
              <p class="wizard-text">Ödeme Yöntemi</p>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav-->
        <!--begin: Wizard Body-->
        <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
          <div class="col-xl-12 col-xxl-9">
            <!--begin: Wizard Form-->
            <form class="form" id="kt_form">
              <!--begin: Wizard Step 1-->
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <h3 class="mb-10 font-weight-bold text-dark">Mülk Çeşidi</h3>
                <div class="row justify-content-center">
                  <div class="col-12">
                    <div class="radio-container">
                      <b-form-radio-group
                        class="radio-group"
                        id="radio-type"
                        name="radio-sub-component"
                        v-model="MulkTipi"
                      >
                        <b-form-radio  v-for="mulk in MulkCesitleri" size="lg" :value="mulk.name" :key="mulk.id">{{ mulk.name }}</b-form-radio>
                       
                      </b-form-radio-group>
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 1-->
              <!--begin: Wizard Step 2-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">Mülk Bilgileri</h4>
                <div v-if="MulkTipi === 2">
                <h4 class="mb-10 font-weight-bold text-dark mt-5">Arsa Türü Bilgileri</h4>
                <div class="row">
                <div class="col-lg-6 col-12">
                      <div class="form-group">
                        <label style="font-size: 12px; font-weight: 400" for=""
                          >Kullanım Amacı
                        </label>
                        <MultiSelect :options="kullanimamaci" :multiple="false" placeholder="Kullanım Amacı" />
                      </div>
                </div>
                <div class="col-lg-6 col-12">
                      <div class="form-group">
                        <label style="font-size: 12px; font-weight: 400" for=""
                          >İmar Durumu
                        </label>
                        <MultiSelect :options="imardurumu" :multiple="false" placeholder="İmar Durumu" />
                      </div>
                </div>
                <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>Yola Uzaklık (Metre)</label>
                        <input
                          type="text"
                          class="form-control form-control-lg"
                          name="package"
                          placeholder="Yola Uzaklık (Metre)"
                        />
                      </div>
                </div>
                <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>Yol Genişliği (Metre)</label>
                        <input
                          type="text"
                          class="form-control form-control-lg"
                          name="package"
                          placeholder="Yol Genişliği (Metre)"
                        />
                      </div>
                </div>
                <div class="col-lg-6 col-12">
                      <div class="form-group">
                        <label style="font-size: 12px; font-weight: 400" for=""
                          >Altyapı ve Hizmetleri
                        </label>
                        <MultiSelect :options="altyapıHizmetleri" :multiple="false" placeholder="Altyapı Hizmetleri" />
                      </div>
                </div>
                <div class="col-lg-6 col-12">
                      <div class="form-group">
                        <label style="font-size: 12px; font-weight: 400" for=""
                          >Tapu Durumu
                        </label>
                        <MultiSelect :options="tapudurumu" :multiple="false" placeholder="Tapu Durumu" />
                      </div>
                </div>
                <div class="col-lg-12 col-12">
                      <div class="form-group">
                        <label style="font-size: 12px; font-weight: 400" for=""
                          >Mevcut bir Yapı veya İmar Çalışması var ise açıklayınız.
                        </label>
                        <textarea name="" id="" cols="30" class="form-control"></textarea>
                      </div>
                </div>
                </div>
                </div>
                <div v-if="MulkTipi === 3">
                <h4 class="mb-10 font-weight-bold text-dark mt-5">Ticari Türü Bilgileri</h4>
                <div class="row">
                  <div class="col-lg-12 col-12">
                      <div class="form-group">
                        <label style="font-size: 12px; font-weight: 400" for=""
                          >Türü
                        </label>
                        <MultiSelect :options="ticarituru" :multiple="false" placeholder="Türü" />
                      </div>
                  </div>
                </div>
                </div>
                <div v-if="MulkTipi === 4">
                  <h4 class="mb-10 font-weight-bold text-dark mt-5">Bina Türü Bilgileri</h4>
                <div class="row">
                  <div class="col-lg-6 col-12">
                      <div class="form-group">
                        <label style="font-size: 12px; font-weight: 400" for=""
                          >Kat Sayısı
                        </label>
                        <MultiSelect :options="katsayisi" :multiple="false" placeholder="Kat Sayısı" />
                      </div>
                  </div>
                  <div class="col-lg-6 col-12">
                      <div class="form-group">
                        <label style="font-size: 12px; font-weight: 400" for=""
                          >Daire Sayısı
                        </label>
                        <MultiSelect :options="dairesayisi" :multiple="false" placeholder="Daire Sayısı" />
                      </div>
                  </div>
                </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                    <label for="baslik">Mülk Adı</label>
                    <input type="text" class="form-control" v-model="Baslik" id="baslik">
                    </div>

                  </div>
                  <div class="col-lg-12 col-12">
                    <div class="form-group">
                      <MultiSelect
                        :key="counties.length"
                        v-model="county"
                        :options="counties"
                        :multiple="false"
                        label="İl"
                        placeholder="İl Seçiniz"
                        id="il"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12 col-12">
                    <div class="form-group">
                      <MultiSelect
                        :key="districts.length"
                        v-model="district"
                        :options="districts"
                        :multiple="false"
                        label="İlçe"
                        placeholder="İlçe Seçiniz"
                        id="ilce"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12 col-12">
                    <div class="form-group">
                      <MultiSelect
                        :key="neighbourhoods.length"
                        v-model="neighbourhood"
                        :options="neighbourhoods"
                        :multiple="false"
                        label="Mahalle"
                        placeholder="Mahalle Seçiniz"
                        id="mahalle"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label for="cadde">Cadde</label>
                      <input
                        type="text"
                        id="cadde"
                        v-model="CaddeId"
                        class="form-control"
                        placeholder="Cadde"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label for="sokak">Sokak</label>
                      <input
                        type="text"
                        id="sokak"
                        v-model="SokakId"
                        class="form-control"
                        placeholder="Sokak"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label for="binano">Kat Numarası</label>
                      <input
                        type="text"
                        id="katno"
                        v-model="KatNo"
                        class="form-control"
                        placeholder="Kat Numarası"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label for="binano">Bina Numarası</label>
                      <input
                        type="text"
                        id="binano"
                        v-model="BinaNo"
                        class="form-control"
                        placeholder="Bina Numarası"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label for="daire">Daire Numarası</label>
                      <input
                        type="text"
                        id="daire"
                        v-model="DaireNo"
                        class="form-control"
                        placeholder="Daire Numarası"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label for="ada">Ada No</label>
                      <input
                        type="text"
                        id="ada"
                        v-model="AdaBilgisi"
                        class="form-control"
                        placeholder="Ada No"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label for="parsel">Parsel</label>
                      <input
                        type="text"
                        id="parsel"
                        v-model="ParselBilgisi"
                        class="form-control"
                        placeholder="Parsel"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12 col-12">
                    <div class="form-group">
                      <label>Açık Adres</label>
                      <Textarea
                        v-model="AcikAdres"
                        placeholder="Açık adres bilgilerini buraya girebilirsiniz."
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label for="m2">m2 Büyüklüğü</label>
                      <input
                        type="text"
                        id="m2"
                        v-model="MetreKare"
                        class="form-control"
                        placeholder="m2"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label for="yapimyili">Yapım Yılı</label>
                      <input
                        type="text"
                        id="yapimyili"
                        v-model="YapimYili"
                        class="form-control"
                        placeholder="Yapım Yılı"
                      />
                    </div>
                  </div>
                  <div class="col-lg-4 col-12">
                    <b-form-group class="mb-0">
                      <label for="file"> Fotoğraf Ekle </label>
                      <b-form-file
                        id="file"
                        class="rounded-sm"
                        placeholder="Dosya seçiniz"
                        drop-placeholder="Dosyayı buraya sürükleyebilirsiniz"
                        accept="image/*"
                        multiple
                      >
                      </b-form-file>
                    </b-form-group>
                  </div>
                  <div class="col-lg-4 col-12">
                    <b-form-group class="mb-0">
                      <label for="file"> Tapu Bilgileri </label>
                      <b-form-file
                        id="file"
                        class="rounded-sm"
                        placeholder="Dosya seçiniz"
                        drop-placeholder="Dosyayı buraya sürükleyebilirsiniz"
                        accept="image/*"
                        multiple
                      >
                      </b-form-file>
                    </b-form-group>
                  </div>
                  <div class="col-lg-4 col-12">
                    <b-form-group class="mb-0">
                      <label for="file"> Vergi Bilgileri </label>
                      <b-form-file
                        id="file"
                        class="rounded-sm"
                        placeholder="Dosya seçiniz"
                        drop-placeholder="Dosyayı buraya sürükleyebilirsiniz"
                        accept="image/*"
                        multiple
                      >
                      </b-form-file>
                    </b-form-group>
                  </div>
                  <div class="col-md-6 col-12 mt-5">
                    <div class="form-group">
                      <label for="masraf">Aidat ve Diğer Masraflar</label>
                      <input
                        type="text"
                        id="masraf"
                        class="form-control"
                        placeholder="Yapım Yılı"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12 mt-5">
                    <div class="form-group">
                      <label for="kiracidurumu">Kiracı Durumu</label>
                      <select name="" id="kiracidurumu" class="form-control" v-model="MulkDurumu">
                        <option value="" selected>Seçiniz</option>
                        <option value="Kiracı Var">Kiracı Var</option>
                        <option value="Kendi Oturuyor">Kiracı Yok</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label for="kiralamasuresi">Kiralama Süresi</label>
                      <input
                        type="text"
                        id="ada"
                        v-model="KiralamaSuresi"
                        class="form-control"
                        placeholder="Kiralama Süresi"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label for="fiyat">Fiyat</label>
                      <input
                        type="text"
                        id="fiyat"
                        v-model="Fiyat"
                        class="form-control"
                        placeholder="Fiyat"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label for="parabirimi">Para Birimi</label>
                      <select name="" id="parabirimi" class="form-control" v-model="ParaBirimi">
                        <option value="">Para Birimi Seçiniz</option>
                        <option value="TL">TL</option>
                        <option value="Euro">Euro</option>
                        <option value="Dolar">Dolar</option>
                      </select>
                    </div>
                  </div>
                </div>
                <h4 class="mb-10 font-weight-bold text-dark">Kiracı Bilgileri</h4>
                <MultiSelect :options="Kiracilar" v-model="kiraci" :multiple="false" placeholder="Kiracılar" />
                <h4 class="mb-10 font-weight-bold text-dark">Mülk Sahibi Bilgileri</h4>
                <MultiSelect :options="Owners" v-model="owner" :multiple="false" placeholder="Mülk Sahipleri" />

                <!-- <div class="row">
                  <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>İsim</label>
                        <input
                          type="text"
                          class="form-control form-control-lg"
                          name="package"
                          placeholder="İsim"
                        />
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>Soyisim</label>
                        <input
                          type="text"
                          class="form-control form-control-lg"
                          name="package"
                          placeholder="Soyisim"
                        />
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>Telefon</label>
                        <input
                          type="text"
                          class="form-control form-control-lg"
                          name="package"
                          placeholder="Telefon"
                        />
                      </div>
                    </div>
                    <div class="col-md-6 col-12">
                      <div class="form-group">
                        <label>Email</label>
                        <input
                          type="text"
                          class="form-control form-control-lg"
                          name="package"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                </div> -->
                <h4 class="mb-10 font-weight-bold text-dark">Genel Özellikler</h4>
                <div class="row">
                  <div class="col-lg-6 col-12">
                      <div class="form-group">
                        <label style="font-size: 12px; font-weight: 400" for=""
                          >Oda Sayısı
                        </label>
                        <MultiSelect :options="options1" :multiple="false" placeholder="Oda Sayısı" />
                      </div>
                  </div>
                  <div class="col-lg-6 col-12">
                      <div class="form-group">
                        <label style="font-size: 12px; font-weight: 400" for=""
                          >Banyo Sayısı
                        </label>
                        <MultiSelect :options="options2" :multiple="false" placeholder="Banyo Sayısı" />
                      </div>
                  </div>
                  <div class="col-lg-12 col-12">
                      <div class="accordion" role="tablist">
                    <!-- cephesi tab -->
                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button
                          block
                          v-b-toggle.accordion-1
                          variant="success"
                          class="d-flex justify-content-between"
                          style="color: white"
                        >
                          <p>Cephesi</p>
                          <i class="fas fa-chevron-down text-white"></i>
                        </b-button>
                      </b-card-header>
                      <b-collapse
                        id="accordion-1"
                        visible="true"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <div class="row">
                              <div class="col-3">
                                <b-form-checkbox value="adsl"
                                  >Batı</b-form-checkbox
                                >
                              </div>
                              <div class="col-3">
                                <b-form-checkbox value="akilliev"
                                  >Doğu</b-form-checkbox
                                >
                              </div>
                              <div class="col-3">
                                <b-form-checkbox value="akilliev"
                                  >Güney</b-form-checkbox
                                >
                              </div>
                              <div class="col-3">
                                <b-form-checkbox value="akilliev"
                                  >Kuzey</b-form-checkbox
                                >
                              </div>
                            </div>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                    <!-- iç özellikleri tab -->
                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button
                          block
                          v-b-toggle.accordion-2
                          variant="success"
                          class="d-flex justify-content-between"
                          style="color: white"
                        >
                          <p>İç Özellikleri</p>
                          <i class="fas fa-chevron-down text-white"></i>
                        </b-button>
                      </b-card-header>
                      <b-collapse
                        id="accordion-2"
                        visible="true"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <div class="row">
                              <div class="col-3">
                                <b-form-checkbox value="adsl"
                                  >ADSL</b-form-checkbox
                                >
                                <b-form-checkbox value="ahsapdograma"
                                  >Ahşap Doğrama</b-form-checkbox
                                >
                                <b-form-checkbox value="akilliev"
                                  >Akıllı Ev</b-form-checkbox
                                >
                                <b-form-checkbox value="alaramhirsiz"
                                  >Alarm (Hırsız)</b-form-checkbox
                                >
                                <b-form-checkbox value="adsl"
                                  >ADSL</b-form-checkbox
                                >
                                <b-form-checkbox value="ahsapdograma"
                                  >Ahşap Doğrama</b-form-checkbox
                                >
                              </div>
                              <div class="col-3">
                                <b-form-checkbox value="akilliev"
                                  >Akıllı Ev</b-form-checkbox
                                >
                                <b-form-checkbox value="alaramhirsiz"
                                  >Alarm (Hırsız)</b-form-checkbox
                                >
                                <b-form-checkbox value="adsl"
                                  >ADSL</b-form-checkbox
                                >
                                <b-form-checkbox value="ahsapdograma"
                                  >Ahşap Doğrama</b-form-checkbox
                                >
                                <b-form-checkbox value="akilliev"
                                  >Akıllı Ev</b-form-checkbox
                                >
                                <b-form-checkbox value="alaramhirsiz"
                                  >Alarm (Hırsız)</b-form-checkbox
                                >
                              </div>
                              <div class="col-3">
                                <b-form-checkbox value="akilliev"
                                  >Akıllı Ev</b-form-checkbox
                                >
                                <b-form-checkbox value="alaramhirsiz"
                                  >Alarm (Hırsız)</b-form-checkbox
                                >
                                <b-form-checkbox value="adsl"
                                  >ADSL</b-form-checkbox
                                >
                                <b-form-checkbox value="ahsapdograma"
                                  >Ahşap Doğrama</b-form-checkbox
                                >
                                <b-form-checkbox value="akilliev"
                                  >Akıllı Ev</b-form-checkbox
                                >
                                <b-form-checkbox value="alaramhirsiz"
                                  >Alarm (Hırsız)</b-form-checkbox
                                >
                              </div>
                              <div class="col-3">
                                <b-form-checkbox value="akilliev"
                                  >Akıllı Ev</b-form-checkbox
                                >
                                <b-form-checkbox value="alaramhirsiz"
                                  >Alarm (Hırsız)</b-form-checkbox
                                >
                                <b-form-checkbox value="adsl"
                                  >ADSL</b-form-checkbox
                                >
                                <b-form-checkbox value="ahsapdograma"
                                  >Ahşap Doğrama</b-form-checkbox
                                >
                                <b-form-checkbox value="akilliev"
                                  >Akıllı Ev</b-form-checkbox
                                >
                                <b-form-checkbox value="alaramhirsiz"
                                  >Alarm (Hırsız)</b-form-checkbox
                                >
                              </div>
                            </div>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                    <!-- dış özellikleri tab -->
                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button
                          block
                          v-b-toggle.accordion-3
                          variant="success"
                          class="d-flex justify-content-between"
                          style="color: white"
                        >
                          <p>Dış Özellikleri</p>
                          <i class="fas fa-chevron-down"></i>
                        </b-button>
                      </b-card-header>
                      <b-collapse
                        id="accordion-3"
                        visible
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <div class="row">
                              <div class="col-3">
                                <b-form-checkbox value="724guvenlik"
                                  >7/24 Güvenlik</b-form-checkbox
                                >
                                <b-form-checkbox value="apartmangorevlisi"
                                  >Apartman Görevlisi</b-form-checkbox
                                >
                                <b-form-checkbox value="asansor"
                                  >Asansör</b-form-checkbox
                                >
                                <b-form-checkbox value="buharodasi"
                                  >Buhar Odası</b-form-checkbox
                                >
                                <b-form-checkbox value="oyunparki"
                                  >Çocuk Oyun Parkı</b-form-checkbox
                                >
                                <b-form-checkbox value="hamam"
                                  >Hamam</b-form-checkbox
                                >
                              </div>
                              <div class="col-3">
                                <b-form-checkbox value="724guvenlik"
                                  >7/24 Güvenlik</b-form-checkbox
                                >
                                <b-form-checkbox value="apartmangorevlisi"
                                  >Apartman Görevlisi</b-form-checkbox
                                >
                                <b-form-checkbox value="asansor"
                                  >Asansör</b-form-checkbox
                                >
                                <b-form-checkbox value="buharodasi"
                                  >Buhar Odası</b-form-checkbox
                                >
                                <b-form-checkbox value="oyunparki"
                                  >Çocuk Oyun Parkı</b-form-checkbox
                                >
                                <b-form-checkbox value="hamam"
                                  >Hamam</b-form-checkbox
                                >
                              </div>
                              <div class="col-3">
                                <b-form-checkbox value="724guvenlik"
                                  >7/24 Güvenlik</b-form-checkbox
                                >
                                <b-form-checkbox value="apartmangorevlisi"
                                  >Apartman Görevlisi</b-form-checkbox
                                >
                                <b-form-checkbox value="asansor"
                                  >Asansör</b-form-checkbox
                                >
                                <b-form-checkbox value="buharodasi"
                                  >Buhar Odası</b-form-checkbox
                                >
                                <b-form-checkbox value="oyunparki"
                                  >Çocuk Oyun Parkı</b-form-checkbox
                                >
                                <b-form-checkbox value="hamam"
                                  >Hamam</b-form-checkbox
                                >
                              </div>
                              <div class="col-3">
                                <b-form-checkbox value="724guvenlik"
                                  >7/24 Güvenlik</b-form-checkbox
                                >
                                <b-form-checkbox value="apartmangorevlisi"
                                  >Apartman Görevlisi</b-form-checkbox
                                >
                                <b-form-checkbox value="asansor"
                                  >Asansör</b-form-checkbox
                                >
                                <b-form-checkbox value="buharodasi"
                                  >Buhar Odası</b-form-checkbox
                                >
                                <b-form-checkbox value="oyunparki"
                                  >Çocuk Oyun Parkı</b-form-checkbox
                                >
                                <b-form-checkbox value="hamam"
                                  >Hamam</b-form-checkbox
                                >
                              </div>
                            </div>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                    <!-- Muhit Özellikleri -->
                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button
                          block
                          v-b-toggle.accordion-4
                          variant="success"
                          class="d-flex justify-content-between"
                          style="color: white"
                        >
                          <p>Muhit Özellikleri</p>
                          <i class="fas fa-chevron-down"></i>
                        </b-button>
                      </b-card-header>
                      <b-collapse
                        id="accordion-4"
                        visible
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <div class="row">
                              <div class="col-3">
                                <b-form-checkbox value="avm"
                                  >Alışveriş Merkezi</b-form-checkbox
                                >
                                <b-form-checkbox value="belediye"
                                  >Belediye</b-form-checkbox
                                >
                                <b-form-checkbox value="cami"
                                  >Cami</b-form-checkbox
                                >
                                <b-form-checkbox value="cemevi"
                                  >Cemevi</b-form-checkbox
                                >
                                <b-form-checkbox value="denizesifir"
                                  >Denize Sıfır</b-form-checkbox
                                >
                                <b-form-checkbox value="eczane"
                                  >Eczane</b-form-checkbox
                                >
                              </div>
                              <div class="col-3">
                                <b-form-checkbox value="avm"
                                  >Alışveriş Merkezi</b-form-checkbox
                                >
                                <b-form-checkbox value="belediye"
                                  >Belediye</b-form-checkbox
                                >
                                <b-form-checkbox value="cami"
                                  >Cami</b-form-checkbox
                                >
                                <b-form-checkbox value="cemevi"
                                  >Cemevi</b-form-checkbox
                                >
                                <b-form-checkbox value="denizesifir"
                                  >Denize Sıfır</b-form-checkbox
                                >
                                <b-form-checkbox value="eczane"
                                  >Eczane</b-form-checkbox
                                >
                              </div>
                              <div class="col-3">
                                <b-form-checkbox value="avm"
                                  >Alışveriş Merkezi</b-form-checkbox
                                >
                                <b-form-checkbox value="belediye"
                                  >Belediye</b-form-checkbox
                                >
                                <b-form-checkbox value="cami"
                                  >Cami</b-form-checkbox
                                >
                                <b-form-checkbox value="cemevi"
                                  >Cemevi</b-form-checkbox
                                >
                                <b-form-checkbox value="denizesifir"
                                  >Denize Sıfır</b-form-checkbox
                                >
                                <b-form-checkbox value="eczane"
                                  >Eczane</b-form-checkbox
                                >
                              </div>
                              <div class="col-3">
                                <b-form-checkbox value="avm"
                                  >Alışveriş Merkezi</b-form-checkbox
                                >
                                <b-form-checkbox value="belediye"
                                  >Belediye</b-form-checkbox
                                >
                                <b-form-checkbox value="cami"
                                  >Cami</b-form-checkbox
                                >
                                <b-form-checkbox value="cemevi"
                                  >Cemevi</b-form-checkbox
                                >
                                <b-form-checkbox value="denizesifir"
                                  >Denize Sıfır</b-form-checkbox
                                >
                                <b-form-checkbox value="eczane"
                                  >Eczane</b-form-checkbox
                                >
                              </div>
                            </div>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                    <!-- ulaşım -->
                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button
                          block
                          v-b-toggle.accordion-5
                          variant="success"
                          class="d-flex justify-content-between"
                          style="color: white"
                        >
                          <p>Ulaşım</p>
                          <i class="fas fa-chevron-down"></i>
                        </b-button>
                      </b-card-header>
                      <b-collapse
                        id="accordion-5"
                        visible
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <div class="row">
                              <div class="col-3">
                                <b-form-checkbox value="anayol"
                                  >Anayol</b-form-checkbox
                                >
                                <b-form-checkbox value="avrasyatunel"
                                  >Avrasya Tüneli</b-form-checkbox
                                >
                                <b-form-checkbox value="bogazkopruleri"
                                  >Boğaz Köprüleri</b-form-checkbox
                                >
                                <b-form-checkbox value="cadde"
                                  >Cadde</b-form-checkbox
                                >
                                <b-form-checkbox value="denizotobusu"
                                  >Deniz Otobüsü</b-form-checkbox
                                >
                                <b-form-checkbox value="dolmus"
                                  >Dolmuş</b-form-checkbox
                                >
                              </div>
                              <div class="col-3">
                                <b-form-checkbox value="anayol"
                                  >Anayol</b-form-checkbox
                                >
                                <b-form-checkbox value="avrasyatunel"
                                  >Avrasya Tüneli</b-form-checkbox
                                >
                                <b-form-checkbox value="bogazkopruleri"
                                  >Boğaz Köprüleri</b-form-checkbox
                                >
                                <b-form-checkbox value="cadde"
                                  >Cadde</b-form-checkbox
                                >
                                <b-form-checkbox value="denizotobusu"
                                  >Deniz Otobüsü</b-form-checkbox
                                >
                                <b-form-checkbox value="dolmus"
                                  >Dolmuş</b-form-checkbox
                                >
                              </div>
                              <div class="col-3">
                                <b-form-checkbox value="anayol"
                                  >Anayol</b-form-checkbox
                                >
                                <b-form-checkbox value="avrasyatunel"
                                  >Avrasya Tüneli</b-form-checkbox
                                >
                                <b-form-checkbox value="bogazkopruleri"
                                  >Boğaz Köprüleri</b-form-checkbox
                                >
                                <b-form-checkbox value="cadde"
                                  >Cadde</b-form-checkbox
                                >
                                <b-form-checkbox value="denizotobusu"
                                  >Deniz Otobüsü</b-form-checkbox
                                >
                                <b-form-checkbox value="dolmus"
                                  >Dolmuş</b-form-checkbox
                                >
                              </div>
                              <div class="col-3">
                                <b-form-checkbox value="anayol"
                                  >Anayol</b-form-checkbox
                                >
                                <b-form-checkbox value="avrasyatunel"
                                  >Avrasya Tüneli</b-form-checkbox
                                >
                                <b-form-checkbox value="bogazkopruleri"
                                  >Boğaz Köprüleri</b-form-checkbox
                                >
                                <b-form-checkbox value="cadde"
                                  >Cadde</b-form-checkbox
                                >
                                <b-form-checkbox value="denizotobusu"
                                  >Deniz Otobüsü</b-form-checkbox
                                >
                                <b-form-checkbox value="dolmus"
                                  >Dolmuş</b-form-checkbox
                                >
                              </div>
                            </div>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                    <!-- manzara -->
                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button
                          block
                          v-b-toggle.accordion-6
                          variant="success"
                          class="d-flex justify-content-between"
                          style="color: white"
                        >
                          <p>Ulaşım</p>
                          <i class="fas fa-chevron-down"></i>
                        </b-button>
                      </b-card-header>
                      <b-collapse
                        id="accordion-6"
                        visible
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <div class="row">
                              <div class="col-3">
                                <b-form-checkbox value="bogaz"
                                  >Boğaz</b-form-checkbox
                                >
                                <b-form-checkbox value="deniz"
                                  >Deniz</b-form-checkbox
                                >
                                <b-form-checkbox value="doga"
                                  >Doğa</b-form-checkbox
                                >
                                <b-form-checkbox value="gol"
                                  >Göl</b-form-checkbox
                                >
                                <b-form-checkbox value="havuz"
                                  >Havuz</b-form-checkbox
                                >
                                <b-form-checkbox value="park"
                                  >Park / Yeşil Alan</b-form-checkbox
                                >
                              </div>
                              <div class="col-3">
                                <b-form-checkbox value="sehir"
                                  >Şehir</b-form-checkbox
                                >
                                <b-form-checkbox value="caddesk"
                                  >Cadde/Sokak</b-form-checkbox
                                >
                                <b-form-checkbox value="bahce"
                                  >Bahçe</b-form-checkbox
                                >
                              </div>
                            </div>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                    <!-- engelliye uygun mu -->
                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button
                          block
                          v-b-toggle.accordion-7
                          variant="success"
                          class="d-flex justify-content-between"
                          style="color: white"
                        >
                          <p>Engelliye Uygun</p>
                          <i class="fas fa-chevron-down"></i>
                        </b-button>
                      </b-card-header>
                      <b-collapse
                        id="accordion-7"
                        visible
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-text>
                            <div class="row">
                              <div class="col-3">
                                <b-form-checkbox value="parkyeri"
                                  >Araç Park Yeri</b-form-checkbox
                                >
                                <b-form-checkbox value="asansor"
                                  >Asansör</b-form-checkbox
                                >
                                <b-form-checkbox value="banyo"
                                  >Banyo</b-form-checkbox
                                >
                                <b-form-checkbox value="geniskoridor"
                                  >Geniş Koridor</b-form-checkbox
                                >
                                <b-form-checkbox value="girisrampa"
                                  >Giriş / Rampa</b-form-checkbox
                                >
                                <b-form-checkbox value="merdiven"
                                  >Merdiven</b-form-checkbox
                                >
                              </div>
                              <div class="col-3">
                                <b-form-checkbox value="parkyeri"
                                  >Araç Park Yeri</b-form-checkbox
                                >
                                <b-form-checkbox value="asansor"
                                  >Asansör</b-form-checkbox
                                >
                                <b-form-checkbox value="banyo"
                                  >Banyo</b-form-checkbox
                                >
                                <b-form-checkbox value="geniskoridor"
                                  >Geniş Koridor</b-form-checkbox
                                >
                                <b-form-checkbox value="girisrampa"
                                  >Giriş / Rampa</b-form-checkbox
                                >
                                <b-form-checkbox value="merdiven"
                                  >Merdiven</b-form-checkbox
                                >
                              </div>
                              <div class="col-3">
                                <b-form-checkbox value="parkyeri"
                                  >Araç Park Yeri</b-form-checkbox
                                >
                                <b-form-checkbox value="asansor"
                                  >Asansör</b-form-checkbox
                                >
                                <b-form-checkbox value="banyo"
                                  >Banyo</b-form-checkbox
                                >
                                <b-form-checkbox value="geniskoridor"
                                  >Geniş Koridor</b-form-checkbox
                                >
                                <b-form-checkbox value="girisrampa"
                                  >Giriş / Rampa</b-form-checkbox
                                >
                                <b-form-checkbox value="merdiven"
                                  >Merdiven</b-form-checkbox
                                >
                              </div>
                              <div class="col-3">
                                <b-form-checkbox value="parkyeri"
                                  >Araç Park Yeri</b-form-checkbox
                                >
                                <b-form-checkbox value="asansor"
                                  >Asansör</b-form-checkbox
                                >
                                <b-form-checkbox value="banyo"
                                  >Banyo</b-form-checkbox
                                >
                                <b-form-checkbox value="geniskoridor"
                                  >Geniş Koridor</b-form-checkbox
                                >
                                <b-form-checkbox value="girisrampa"
                                  >Giriş / Rampa</b-form-checkbox
                                >
                                <b-form-checkbox value="merdiven"
                                  >Merdiven</b-form-checkbox
                                >
                              </div>
                            </div>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                    </div>


                </div>
              </div>
              <!--end: Wizard Step 2-->
              <!--begin: Wizard Step 3-->
              <div class="pb-5" data-wizard-type="step-content">
              <h4 class="mb-10 font-weight-bold text-dark">Ödeme Yöntemi</h4>
              <div class="row">
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Kiranın Yatırılacağı Hesap</label>
                      <select name="delivery" class="form-control form-control-lg">
                    <option value="" selected>Seçiniz</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Kiranın Yatırılacağı Kişi</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="package"
                        placeholder="Yatırılan Kişi İsmi"
                      />
                    </div>
                  </div>
                  <div class="col-12 my-5">
                    <b-form-checkbox  v-model="checked" name="check-button" switch size="lg">Kira takibimin MülkPlus tarafından yapılmasını istiyorum.</b-form-checkbox>
                  </div>
              </div>
             </div>
              <!--end: Wizard Step 3-->
              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <div class="mr-2">
                  <button
                    class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-prev"
                  >
                    Geri
                  </button>
                </div>
                <div>
                  <button
                    type="submit"
                    v-on:click="submit"
                    class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-submit"
                  >
                    Gönder
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-next"
                  >
                    İleri
                  </button>
                </div>
              </div>
              <!--end: Wizard Actions -->
            </form>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
    </div>
    <!--end: Wizard-->
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";

.wizard-text {
  color: $dveb-primary;
  font-size: 1.38462rem;
  font-family: "Poppins";
  line-height: 1.53846rem;

  @media (max-width: 990px) {
    flex: auto;
    text-align: start;
    margin-left: 1rem;
  }

  &:not(:last-child) {
    flex: 1;
  }
}

.wizard-step:not(:last-child) {
  flex: 1;
  position: relative;
}

.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label {
  margin: 0;
}

.wizard-divider {
  height: 1rem;
  background-color: $dveb-divider;
  flex: 1;

 
  &:not(:first-child)::before {
    content: '';
    border-bottom: 10px solid #dddfe1;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    height: 0;
    width: 21px;
    position: absolute;
    left: -13px;
    top: 20px;
    transform: rotate(-90deg);
  }

  &::after {
    content: '';
    border-bottom: 10px solid #dddfe1;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    height: 0;
    width: 21px;
    position: absolute;
    left: 40px;
    top: 40%;
    transform: rotate(90deg);
  }
}

.wizard-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.6875rem;
  height: 3.6875rem;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid $dveb-divider;
  font-size: 18px;
  font-weight: 600;
  position: relative;
  z-index: 2;
}

.wizard-step[data-wizard-state="current"],
.wizard-step[data-wizard-state="done"] {
  .wizard-label .wizard-box {
    background-color: $dveb-info;
    color: #fff;
    border: none;
  }
}


.radio-container {
  border: 0.5px solid rgba($dveb-primary, 0.5);
  padding: 3.07692rem;
  border-radius: 10px;

  .radio-group {
    display: flex;
    justify-content: space-between;

    @media (max-width: 990px) {
      flex-direction: column;
      gap: 1.5rem;
    }

    label {
      font-size: 1.53846rem;
      cursor: pointer;

      &::before {
        border-radius: 5px;
        width: 1.84615rem;
        height: 1.84615rem;
        border-color: rgba($dveb-primary, 1);
        border-width: 2px;
        left: -3rem;
      }

      &::after {
        width: 1.84615rem;
        height: 1.84615rem;
        left: -3rem;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E") !important;
      }
    }
  }
}

.form-control {
  border-color: $dveb-primary;
  background-color: #fff;

  &:focus {
    background-color: inherit;
  }
}

.tag-area {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid $dveb-primary;
  border-radius: 4px;
  padding: 0.65rem 1rem;
  gap: 0.5rem;

  span {
    align-items: center;
    border-radius: 0.375rem;
    display: inline-flex;
    justify-content: center;
    padding: 0.15rem 0.25rem;
    color: #fff;

    button {
      padding: 0 0.5rem;
    }
  }

  input {
    outline: none;
    width: 50%;
  }
}
.icon-input {
  i {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0.65rem 1rem;
    color: $dveb-primary;
  }
  input {
    margin-left: 1.5rem;
  }
}
.file-input-label {
  padding: 10px;
  border: 1px solid $dveb-primary;
  border-radius: 5px;
  width: 100%;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import MaskedInput from "vue-masked-input";
import { mapGetters } from "vuex";
import {
  COUNTIES,
  DISTRICTS,
  NEIGHBOURHOODS,
} from "@/core/services/store/location.module.js";

export default {
  name: "mulk-ekle",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Mülker" },
      { title: "Mülk Ekle" },
    ]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v1", {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function (/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on("change", function (wizardObj) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
    this.getCustomers();
    this.getTenant();
  },
  created() {
    this.$store.dispatch(COUNTIES);
  },
  data() {
    return {

      tags: [],
      colors: [
        "#3F98FF80",
        "#10264080",
        "#4033FF80",
        "#2EB6E880",
        "#B3750980",
        "#2E54E880",
      ],
      MulkCesitleri:[
        {id:1,name:"Ev"},
        {id:2,name:"Arsa"},
        {id:3,name:"Ticari"},
        {id:4,name:"Bina"},
      ],
      MulkBilgileri:[
        {id:1,name:"Banyo",cesitId:1},
        {id:2,name:"Banyo Daire",cesitId:2},
        {id:3,name:"Banyo Ticari",cesitId:3},
        {id:4,name:"Banyo Bina",cesitId:4},
      ],
      MulkBilgi: {},
      MulkTipi: "",
      counties: [],
      districts: [],
      neighbourhoods: [],
      neighbourhood: "",

      county: {},
      district: {},
      AcikAdres: "",
      MulkDurumu: "",
      Fiyat: "",
      Baslik: "",
      ParaBirimi: "",
      CaddeId: "",
      SokakId: "",
      BinaNo: "",
      KatNo: "",
      DaireNo: "",
      Owners: [],
      owner: "",
      Kiracilar: [],
      Kiraci: "",
      Icerik: "",
      AdaBilgisi: "",
      ParselBilgisi: "",
      MetreKare:"",
      YapimYili:"",
      options1: [
        { text: "1+0", value: "1" },
        { text: "1+1", value: "2" },
        { text: "2+1 ", value: "3" },
        { text: "3+1", value: "4" },
      ],
      options2: [
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
      ],
      ticarituru: [
      { text: "İşyeri", value: "1" },
      { text: "Fabrika", value: "2" },
      ],
      katsayisi: [
      { text: "1", value: "1" },
      { text: "2", value: "2" },
      { text: "3", value: "3" },
      { text: "4", value: "4" },
      { text: "5", value: "5" },
      { text: "5 ve üzeri", value: "6" },
      ],
      dairesayisi: [
      { text: "1", value: "1" },
      { text: "2", value: "2" },
      { text: "3", value: "3" },
      { text: "4", value: "4" },
      { text: "5", value: "5" },
      { text: "5 ve üzeri", value: "6" },
      ],
      altyapıHizmetleri: [
      { text: "1", value: "1" },
      { text: "2", value: "2" },
      { text: "3", value: "3" },
      { text: "4", value: "4" },
      { text: "5", value: "5" },
      { text: "5 ", value: "6" },
      ],
      kullanimamaci: [
      { text: "Konut", value: "1" },
      { text: "Ticari", value: "2" },
      { text: "Sanayi", value: "3" },
      { text: "Tarım", value: "4" },
      ],
      imardurumu: [
      { text: "Şifai İmar Durumu", value: "1" },
      { text: "Resmi İmar Durumu", value: "2" },
      ],
      tapudurumu: [
      { text: "Hisseli Tapu", value: "1" },
      { text: "Kat İrtifaklı Tapu", value: "2" },
      { text: "Kat Mülkiyetli Tapu", value: "2" },
      ],

    };
  },
  methods: {
    submit: function (e) {
      e.preventDefault();
      this.addProperty().then((result) => {
        if (result.status === 200) {
          this.$generateNotification(this, "success", result.result);
        } else {
          this.$generateNotification(this, "error", result.result);
        }
      });
    },
    addTag() {
      if (this.tagInput.trim() !== "") {
        const newTag = this.tagInput.trim();
        if (!this.tags.includes(newTag)) {
          this.tags.push(newTag);
        }
        this.tagInput = ""; // Input alanını sıfırla
      }
    },
    addProperty() {
      const formData = {
        Baslik: this.Baslik,
        Etiket: this.tags,
        MulkTipi: this.MulkTipi,
        IlId: this.county.value,
        IlceId: this.district.value,
        MahalleId: this.neighbourhood.value,
        CaddeId: this.CaddeId,
        SokakId: this.SokakId,
        BinaNo: this.BinaNo,
        DaireNo: this.DaireNo,
        Fiyat: this.Fiyat,
        ParaBirimi: this.ParaBirimi,
        token: this.myUser,
        Icerik: this.Icerik,
        KiraciId: this.kiraci.value,
        MulkSahibiId: this.owner.value,
        MulkDurumu: this.MulkDurumu,
        AcikAdres: this.AcikAdres,
        AdaBilgisi: this.AdaBilgisi,
        ParselBilgisi: this.ParselBilgisi,
        KatNo:this.KatNo,
        MetreKare:this.MetreKare,
        YapimYili:this.YapimYili
        /* OlusturmaZamani:new Date(),
          OlusturmaId : Math.random() * 1000,
          OlusturmaIp : "1.1.1.1",
          IsDeleted : false */
      };
      return new Promise((resolve, reject) => {
        this.$ApiService
          .post("mulkler_create", formData)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    removeTab(tag) {
      const index = this.tags.indexOf(tag);
      if (index !== -1) {
        this.tags.splice(index, 1);
      }
    },
    getRandomColor() {
      const randomIndex = Math.floor(Math.random() * this.colors.length);
      return this.colors[randomIndex];
    },
    setCountiesSelectValues(county) {
      if (county?.length > 0) {
        const newCounties = county.map((index, key) => {
          return { text: index.il_adi, value: index.il_id };
        });
        this.counties = newCounties;
      }

      this.countiesLoading = false;
    },
    setDistrictsSelectValues(districts) {
      const setDistrictsSelect = districts.map((index, key) => {
        return { text: index.ilce_adi, value: index.ilce_id };
      });
      return setDistrictsSelect;
    },
    setNeighbourhoodsSelectValues(neighbourhoods) {
      const setNeighbourhoodsSelect = neighbourhoods.map((index, key) => {
        return { value: index.mahalle_id, text: index.mahalle_adi };
      });
      return setNeighbourhoodsSelect;
    },
    setOwnersSelectValues(Owners) {
      if (Owners?.length > 0) {
        const newOwners = Owners.map((index, key) => {
          return { value: index._id.$oid, text: index.Adi  + " " + index.Soyadi };
        });
        this.Owners = newOwners;
      }
    },
    setTenantSelectValues(Kiracilar) {
      if (Kiracilar?.length > 0) {
        const newKiracilar = Kiracilar.map((index, key) => {
          return { value: index._id.$oid, text: index.Adi + " " +index.Soyadi };
        });
        this.Kiracilar = newKiracilar;
      }
    },
    onCountyChange() {
      if (this.county) {
        this.$store.dispatch(DISTRICTS, this.county.value).then((districts) => {
          const newDistricts = this.setDistrictsSelectValues(districts);
          this.districts = newDistricts;
        });
      } else {
        this.districts = [];
      }
    },
    onDistrictChange() {
      if (this.district) {
        const payload = {
          il_id: this.county.value,
          ilce_id: this.district.value,
        };
        this.$store.dispatch(NEIGHBOURHOODS, payload).then((street) => {
          const newNeighbourhoods = this.setNeighbourhoodsSelectValues(street);
          this.neighbourhoods = newNeighbourhoods;
        });
      } else {
        this.newNeighbourhoods = [];
      }
    },
    getCustomers() {
      let queryLimit = this.list_length ? this.list_length : 10;
      let query = `musteriler_list?offset=${
        this.page ? this.page : 0
      }&limit=${queryLimit}&token=${this.myUser}&searchValue=&column=`;

      this.$ApiService
        .get(query)
        .then((res) => {
          if (res.status == 200) {
            this.Owners = res.data.result;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTenant() {
      let queryLimit = this.list_length ? this.list_length : 10;
      let query = `musteriler_list?offset=${
        this.page ? this.page : 0
      }&limit=${queryLimit}&token=${
        this.myUser
      }&searchValue=Kiraci&column=MusteriTipi`;

      this.$ApiService
        .get(query)
        .then((res) => {
          if (res.status == 200) {
            this.Kiracilar = res.data.result;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  components: {
    MaskedInput,
  },
  watch: {
    getCounties(newCounties) {
      if (newCounties.length > 0) {
        this.setCountiesSelectValues(newCounties);
      }
    },
    county: "onCountyChange",
    district: "onDistrictChange",
    Owners() {
      if (this.Owners.length > 0) {
        this.setOwnersSelectValues(this.Owners);
      }
    },
    Kiracilar() {
      if (this.Kiracilar.length > 0) {
        this.setTenantSelectValues(this.Kiracilar);
      }
    },
    MulkTipi(){
      this.MulkBilgi = this.MulkBilgileri.filter(bilgi => bilgi.cesitId === this.MulkTipi)
    }
  },
  computed: {
    ...mapGetters(["myUser", "getCounties", "getDistricts"]),
  },
};
</script>
